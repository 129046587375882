<template>
  <div class="con">
    <div class="item" v-for="item in iconList" :key="item.id">
      <div class="img-con">
        <img :src="previewUrl + '?fileId=' + item.icon + '&token=' + token" alt="" />
      </div>
      <p class="item-title">{{ $t(item.name) }}</p>
    </div>
  </div>
</template>

<script>
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import { getWidgetDataSource } from '@/api/form-design/FormApi';
export default {
  data() {
    return {
      iconList: [],
      iconList: [],
      previewUrl: '/river-fs/file/download',
      token: ''
    };
  },
  created() {
    this.token = StorageUtil.getSessionStorage('token');
    let formModelId = this.$route.query.formModelId;
    if (formModelId) {
      let params = { page: { pageIndex: 1, pageSize: 9999 }, formModelId };
      getWidgetDataSource(params).then(res => {
        if (res.code == '1') {
          this.initIconList(res.data.complexDataList[0].pageInfo);
        }
      });
    }
  },
  methods: {
    initIconList(val) {
      let arr = [];
      if (val.list) {
        val.list.map(item => {
          let obj = {};
          item.columns.map(sItem => {
            obj[sItem.key] = sItem.value;
          });
          arr.push(obj);
        });
      }
      this.iconList = arr;
      // this.iconLis
    }
  }
};
</script>
<style lang="scss" scoped>
.con {
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 25%;
    height: auto;
    .item-title {
      width: 100%;
      text-align: center;
    }
    .img-con {
      cursor: pointer;
      width: 60px;
      height: 60px;
      margin: auto;
      box-sizing: border-box;
      padding: 8px;
      background-color: rgba(238, 238, 238, 0.509);
      border-radius: 4px;
      margin-bottom: 4px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
