var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "con" },
    _vm._l(_vm.iconList, function (item) {
      return _c("div", { key: item.id, staticClass: "item" }, [
        _c("div", { staticClass: "img-con" }, [
          _c("img", {
            attrs: {
              src:
                _vm.previewUrl + "?fileId=" + item.icon + "&token=" + _vm.token,
              alt: "",
            },
          }),
        ]),
        _c("p", { staticClass: "item-title" }, [
          _vm._v(_vm._s(_vm.$t(item.name))),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }